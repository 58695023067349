import { Link, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import Filter from "./filter";
import { Layout } from "@components";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useState } from "react"; // Import useState hook

const ALL_PROGRAMS_QUERY = gql`
  query ($client: String, $program: String, $status: String, $page: Int) {
    programs(client: $client, program: $program, status: $status, page: $page) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        id
        clientName
        clientDepartment
        clientContactDetails
        programName
        programStatus
        programVenue
        programPax
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programEmtCost
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programOverhead
        programToolContractPrice
        createdBy {
          id
          firstName
          lastName
        }
        updatedBy {
          id
          firstName
          lastName
        }
        insertedAt
        updatedAt
      }
    }
  }
`;

export default function Program() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { loading, error, data, refetch } = useQuery(ALL_PROGRAMS_QUERY, {
    variables: { page },
  });

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    refetch({ page: e.selected + 1 });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.programs.pageNumber
    ? (data.programs.pageNumber - 1) * data.programs.pageSize + 1
    : 0;
  const endOfResults = data.programs.totalEntries
    ? Math.min(
        startOfResults + data.programs.pageSize - 1,
        data.programs.totalEntries
      )
    : 0;

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Programs
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the projects
              </p>
            </div>

            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link
                to="/programs/new"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add program
              </Link>
            </div>
          </div>

          <div className="mt-4">
            <Filter refetch={refetch} />
          </div>
        </div>

        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Client Name
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Program Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Department
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Created On
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.programs.entries.map((program) => (
                  <tr
                    key={program.clientName}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="relative py-4 pr-3 text-sm font-medium text-gray-900"
                    >
                      {program.clientName}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                    >
                      {program.programName}
                    </td>
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                    >
                      {program.clientDepartment}
                    </td>
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell"
                    >
                      {moment(program.insertedAt).format("LL")}
                    </td>
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                    >
                      {program.createdBy.firstName} {program.createdBy.lastName}
                    </td>
                    <td
                      onClick={() => navigate(`/programs/${program.id}`)}
                      className="px-3 py-4 text-sm text-gray-500 capitalize"
                    >
                      {program.programStatus}
                    </td>
                    <td>
                      <Link
                        to={`/programs/${program.id}`}
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="text-sm text-gray-700">
                  <p>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to{" "}
                    <span className="font-medium">{endOfResults}</span> of{" "}
                    <span className="font-medium">
                      {data.programs.totalEntries}
                    </span>{" "}
                    results
                  </p>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={data.programs.totalPages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
